<template>
  <section title="Clientes">
    <b-card>
      <b-row>
        <vue-confirm-dialog />
        <b-col
          cols="12"
        >
          <b-card-body>
            <div style="display: flex; justify-content: space-between;">
              <div class="titulo-pagina">
                Clientes
              </div>
              <b-button
                variant="outline-success"
                @click="adicionar()"
              >
                <feather-icon
                  icon="UserIcon"
                  class="mr-50"
                />
                Adicionar Cliente
              </b-button>
            </div>

            <b-row class="mb-1">
              <b-col
                md="9"
              />
              <b-col md="3" />
            </b-row>
            <data-table
              :data="data"
              :columns="columns"
              @on-table-props-changed="reloadTable"
            >
              <tbody
                slot="body"
                slot-scope="{
                  // eslint-disable-next-line vue/no-template-shadow
                  data }"
              >
                <tr
                  v-for="item in data"
                  :key="item.id"
                >
                  <td
                    v-for="column in columns"
                    :key="column.name"
                  >
                    <data-table-cell
                      :value="item"
                      :name="column.name"
                      :meta="column.meta"
                      :comp="column.component"
                      :classes="column.classes"
                    />
                    <slot v-if="column.label === 'Ações'">
                      <b-button
                        variant="info"
                        class="btn-icon rounded-circle"
                        @click="EditarProjeto(item)"
                      >
                        <feather-icon icon="Edit3Icon" />
                      </b-button>
                      <b-button
                        variant="danger"
                        class="btn-icon rounded-circle ml-1"
                        @click="DeletarDados(item)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </slot>
                    <slot v-if="column.label === 'status'">
                      <b-badge
                        class="px-2"
                        :variant="NomeStatus(item).cor"
                      >
                        {{ NomeStatus(item).msg }}
                      </b-badge>
                    </slot>
                  </td>
                </tr>
              </tbody>
            </data-table>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="popupEditar"
      title="Editar Cliente"
      hide-footer
      centered
      size="lg"
    >
      <b-row>
        <b-col md="5">
          <b-form-group

            label="Nome:"
            label-for="nome"
          >
            <b-form-input
              id="nome"
              v-model="nome"
              placeholder="Nome do Cliente"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group

            label="E-Mail:"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="email"
              placeholder="E-Mail"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group

            label="CPF/CNPJ:"
            label-for="cpfcnpj"
          >
            <b-form-input
              id="cpfcnpj"
              v-model="cnpj"
              placeholder="CEP/CNPJ"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group

            label="Telefone/Celular:"
            label-for="telefone"
          >
            <b-form-input
              id="telefone"
              v-model="telefone"
              placeholder="Telefone/Celular"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group

            label="Cep"
            label-for="cep"
          >
            <b-form-input
              id="cep"
              v-model="cep"
              placeholder="Cep"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group

            label="Cidade:"
            label-for="cidade"
          >
            <b-form-input
              id="cidade"
              v-model="cidade"
              placeholder="Cidade"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5">
          <b-form-group

            label="Endereço"
            label-for="endereco"
          >
            <b-form-input
              id="endereco"
              v-model="endereco"
              placeholder="Endereço do Fornecedor"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Estado(UF)"
            label-for="estado"
          >
            <b-form-input
              id="estado"
              v-model="estado"
              placeholder="Ex: PR, SC, PA, MT . . ."
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Status:"
            label-for="status"
          >
            <v-select
              v-model="status"
              label="name"
              :options="opcoesstatus"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            block
            @click="SalvarCliente()"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>

import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'IndexClientesLista',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      // ------------- DADOS TABELA -------- \\
      url: 'admin/cliente',
      data: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      columns: [
        {
          label: 'nome',
          name: 'nome',
          orderable: true,
        },
        {
          label: 'telefone',
          name: 'telefone',
          orderable: true,
        },
        {
          label: 'estado',
          name: 'estado',
          orderable: true,
        },
        {
          label: 'cidade',
          name: 'cidade',
          orderable: true,
        },
        {
          label: 'status',
          orderable: true,
        },
        {
          label: 'Ações',
          orderable: false,
        },
      ],
      // -------------------------- Dados Banco -------------------------- \\
      nome: null,
      email: null,
      cnpj: null,
      telefone: null,
      cep: null,
      endereco: null,
      estado: null,
      cidade: null,
      status: null,
      opcoesstatus: [
        { id: 1, name: 'Ativo' },
        { id: 2, name: 'Inativo' },
      ],
    }
  },
  created() {
    this.$loading(true)
    this.BuscarDados(this.url)
  },
  methods: {
    adicionar() {
      localStorage.removeItem('admin/edicao')
      this.$router.push({
        name: 'adm-cliente-adicionar',
      })
    },
    reloadTable(tableProps) {
      this.mostraPopUp = false
      this.BuscarDados(this.url, tableProps)
    },
    async BuscarDados(url = this.url, options = this.tableProps) {
      await this.$http.get(url, { params: options }).then(resp => {
        this.data = resp.data
      })
      this.$loading(false)
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    LimparCampos() {
      this.id = null
      this.nome = null
      this.email = null
      this.cnpj = null
      this.telefone = null
      this.cep = null
      this.endereco = null
      this.estado = null
      this.cidade = null
    },
    SalvarCliente() {
      const obj = {
        nome: this.nome,
        email: this.email,
        cnpj: this.cnpj,
        telefone: this.telefone,
        cep: this.cep,
        endereco: this.endereco,
        estado: this.estado,
        cidade: this.cidade,
        status: this.status.id,
      }
      // --------------------- FUNÇÃO DE EDITAR ---------------------------- //
      this.$http.put(`admin/cliente/${this.id}`, obj).then(resp => {
        if (resp.data.success) {
          this.Notificacao('success', resp.data.success)
        }
        if (resp.data.error) {
          this.Notificacao('danger', resp.data.error)
        }
        this.reloadTable()
      })
    },
    NomeStatus(item) {
      if (item.status === 2) {
        return { cor: 'secondary', msg: 'Inativo' }
      }
      if (item.status === 1) {
        return { cor: 'success', msg: 'Ativo' }
      }
      return { cor: 'danger', msg: 'Sem status' }
    },
    EditarProjeto(item) {
      localStorage.setItem('admin/edicao', JSON.stringify(item))
      this.$router.push({
        name: 'adm-cliente-ver',
        params: {
          id: item.id,
        },
      })
      // this.$router.push(`/admin/cliente/${item.id}`)
      // const clientes = JSON.parse(localStorage.getItem('editclientes'))
      // this.id = clientes.id
      // this.nome = clientes.nome
      // this.email = clientes.email
      // this.cnpj = clientes.cnpj
      // this.telefone = clientes.telefone
      // this.cep = clientes.cep
      // this.endereco = clientes.endereco
      // this.estado = clientes.estado
      // this.cidade = clientes.cidade
      // this.status = clientes.status
    },
    async DeletarDados(item) {
      this.$confirm(
        {
          message: 'Tem certeza que deseja Excluir?',
          button: {
            no: 'Não',
            yes: 'Sim',
          },
          /**
             * Callback Function
             * @param {Boolean} confirm
             */
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`admin/cliente/${item.id}`)
              this.Notificacao('success', 'Cliente Removido com sucesso')
              this.reloadTable()
            }
          },
        },
      )
    },
  },
}
</script>

<style scoped>
</style>
